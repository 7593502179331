import React, { useState, useRef } from 'react';
import fields from './fields';

const Row = ({ rowData, onEdit, onDelete }) => {
  const [isEditing, setEditing] = useState(false);
  const editedDataRefs = useRef({});

  const handleEdit = () => {
    if (isEditing) {
      const updatedData = {
        action: 'edit',
        row_id: rowData.row_id,
      };

      fields.forEach(field => {
        updatedData[field.field] = editedDataRefs.current[field.field].innerText;
      });

      onEdit(updatedData);
      setEditing(false);
    }
  };

  const handleDelete = () => {
    onDelete(rowData.row_id);
  };

  return (
    <tr>
      <td>{rowData.row_id}</td>
      {fields.map(field => (
        <td
          key={field.field}
          ref={ref => (editedDataRefs.current[field.field] = ref)}
          contentEditable={isEditing}
          onClick={() => setEditing(true)}
          onBlur={handleEdit}
          dangerouslySetInnerHTML={{ __html: rowData[field.field] }}
        />
      ))}
      <td>
        <span
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={handleDelete}
        >
          &#10006;
        </span>
      </td>
    </tr>
  );
};

export default Row;