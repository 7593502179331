// Filter.js
import React, { useEffect } from 'react';

const Filter = ({ selectedMonth, selectedYear, setSelectedMonth, setSelectedYear }) => {
  const months = [
    'Januari', 'Februari', 'Mars', 'April',
    'Maj', 'Juni', 'Juli', 'Augusti',
    'September', 'Oktober', 'November', 'December'
  ];

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1; // Månader är 0-indexerade, så lägg till 1
    setSelectedMonth(currentMonth.toString());
    setSelectedYear(currentYear.toString());
  }, [currentYear, setSelectedMonth, setSelectedYear]); // Inkludera beroenden i beroendearrayen

  const years = Array.from({ length: 10 }, (_, index) => (currentYear - index).toString());

  return (
    <div>
      <label htmlFor="month">Månad:</label>
      <select id="month" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
        {months.map((month, index) => (
          <option key={index} value={(index + 1).toString()}>{month}</option>
        ))}
      </select>

      <label htmlFor="year">År:</label>
      <select id="year" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
        {years.map((year, index) => (
          <option key={index} value={year.toString()}>{year}</option>
        ))}
      </select>
    </div>
  );
};

export default Filter;