import React, { useEffect, useState, useCallback } from 'react';
import JakTable from './JakTable';
import Filter from './Filter';
import Button from '@mui/material/Button';

const App = () => {
  console.log('Rendering App component');
  const [data, setData] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const fetchData = useCallback(async () => {
    let url = 'backend.php';
    const params = new URLSearchParams();

     if (selectedMonth || selectedYear) {
      params.append('action', 'getFiltered');
    } else {
      params.append('action', 'get');
    }

    if (selectedMonth) {
      params.append('month', selectedMonth);
    }
    if (selectedYear) {
      params.append('year', selectedYear);
    }

    const response = await fetch(url, {
      method: 'POST',
      body: params,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    const result = await response.json();
    setData(result);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedMonth, selectedYear]);

  const handleAdd = async () => {
    await fetch('backend.php', {
      method: 'POST',
      body: new URLSearchParams({
        action: 'add',
        date: new Date().toISOString().split('T')[0],
        comment: '',
      }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    fetchData();
  };

  const handleEdit = (updatedData) => {
   const formData = new URLSearchParams();
    for (const key in updatedData) {
      formData.append(key, updatedData[key]);
    }

    fetch('backend.php', {
      method: 'POST',
      body: formData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        fetchData(); // Uppdatera data efter redigering
      })
      .catch(error => {
        console.error('Error during edit:', error);
      });
  };

  const handleDelete = async (id) => {
    await fetch('backend.php', {
      method: 'POST',
      body: new URLSearchParams({
        action: 'delete',
        id: id,
      }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    fetchData();
  };

  return (
    <div>
      <h1>My App</h1>
      <Filter
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setSelectedMonth={setSelectedMonth}
        setSelectedYear={setSelectedYear}
      />
      <Button variant="contained" color="primary" onClick={handleAdd}>Lägg till</Button>
      <JakTable
        data={data}
        onEdit={handleEdit}
        onDelete={handleDelete}
        setEditedRow={setEditedRow}
        editedRow={editedRow}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
      />
    </div>
  );
};

export default App;