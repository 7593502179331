// JakTable.js
import React from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Row from './Row';
import fields from './fields';

const JakTable = ({ data, onEdit, onDelete, setEditedRow, editedRow, selectedMonth, selectedYear }) => {

  console.log('selectedMonth:', selectedMonth);
  console.log('selectedYear:', selectedYear);

  const filteredData = data.map((row) => {
    const dateObject = row.row_date instanceof Date ? row.row_date : new Date(row.row_date);
    if (
      (selectedMonth && dateObject.getMonth() + 1 !== parseInt(selectedMonth, 10)) ||
      (selectedYear && dateObject.getFullYear().toString() !== selectedYear)
    ) {
      return null;
    }
    return row;
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            {fields.map((field) => (
              <TableCell key={field.field}>{field.name}</TableCell>
            ))}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map(
            (rowData) =>
              rowData !== null && (
                <Row
                  key={rowData.row_id}
                  rowData={rowData}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  setEditedRow={setEditedRow}
                  editedRow={editedRow}
                  fields={fields}
                />
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default JakTable;